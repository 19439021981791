import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { calcTotalPrice } from '../../utils/calcTotalPrice';
import { getCartFromLS } from '../../utils/getCartFromLS';

import { CartItem } from './types';
import { CartSliceState } from './types';

const initialState: CartSliceState = getCartFromLS();

const cartSlice = createSlice({
    name:'cart',
    initialState,
    reducers: {
        addItem(state, action: PayloadAction<CartItem>) {
          const findItem = state.items.find((obj) => obj.id === action.payload.id)

          if (findItem) {
            findItem.count++;
          } else {
            state.items.push({
                ...action.payload,
                count: 1
            })
          }

          state.totalPrice = calcTotalPrice(state.items);
        },
        minusItem(state, action: PayloadAction<number>) {
          const findItem = state.items.find((obj) => obj.id === action.payload);

            if (findItem) {
              findItem.count--;
              state.totalPrice -= findItem.price; 
            }

            state.totalPrice = calcTotalPrice(state.items);
        },
        removeItem(state, action: PayloadAction<number>) {
          state.items = state.items.filter(obj => obj.id !== action.payload );
          state.totalPrice = calcTotalPrice(state.items);
        },
        clearItems(state) {
          state.items = [];
          state.totalPrice = 0;
        }
    }
})

export const { addItem, minusItem, removeItem, clearItems } = cartSlice.actions;

export default cartSlice.reducer;